<template>
  <div>
    <!--begin::Row-->
    <!-- <div class="row">
        <div class="col-lg-12"> -->
            <div class="card card-custom card-stretch gutter-b">
                <!--begin::Header-->
                <div class="card-header border-0">
                    <h3 class="card-title font-weight-bolder text-dark">Rekapitulasi Nomine Pemda Kabupaten</h3>
                    <div class="card-toolbar">
                        
                    </div>
                </div>
                <!--end::Header-->
                <!--begin::Body-->
                <div class="card-body pt-0">
                    <!--begin::Item-->
                    <div class="mb-12">
                        <!--begin::Content-->
                        <div>
                            <div class="row">
                                    <div class="col-xl-8">
                                    </div>
                                    <div class="col-xl-4">
                                        <b-button  v-if="this.setuju.length === 0" data-toggle="modal" data-target="#persetujuan" variant="primary" style="float: right;">Menyetujui Penetapan Nomine</b-button>
                                        <b-button  v-else disabled variant="primary" style="float: right;">Menyetujui Penetapan Nomine</b-button>
                                    </div>
                            </div>
                            <br>
                            <div style="overflow-x:auto;">
                                <table id="example" class="table table-striped table-bordered" style="width:100%">
                                  <thead>
                                      <tr>
                                          <th rowspan="2">No</th>
                                          <th rowspan="2">Nama Kabupaten</th>
                                          <th style="text-align:center;" colspan="4">Kinerja PTSP</th>
                                          <th style="text-align:center;">Kinerja PPB</th>
                                          <th rowspan="2">Nilai Akhir</th>
                                          <th rowspan="2">Peringkat Sementara</th>
                                          <th rowspan="2">Peringkat Baru</th>
                                          <th rowspan="2">Catatan</th>
                                      </tr>
                                      <tr>
                                          <th>PM PTSP</th>
                                          <th>Penilaian Organisasi Pengusaha</th>
                                          <th>Penilaian Provinsi</th>
                                          <th>Total</th>
                                          <th>PM PPB</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr v-for="row in dataprovinsi1" :key="row.id">
                                          <td></td>
                                          <td><div style="width:150px">{{row.daerah}}</div></td>
                                          <td><div style="width:120px">{{format_desimal(row.total_pm)}} &nbsp; &nbsp;<b-button @click="urlptsp(row.idptsp)" size="sm" style="background-color:#F9F9F9;" v-b-tooltip.hover title="Lihat detail penilaian"><i class="flaticon-eye"></i></b-button></div></td>
                                          <td><div style="width:120px">{{format_desimal(row.total_asosiasi)}}&nbsp; &nbsp;<b-button @click="urlhipmi(row.idptsp)" size="sm" style="background-color:#F9F9F9;" v-b-tooltip.hover title="Lihat detail penilaian"><i class="flaticon-eye"></i></b-button></div></td>
                                          <td><div style="width:120px">{{format_desimal(row.total_pk)}}&nbsp; &nbsp;<b-button @click="urlppkprovinsi(row.idptsp)" size="sm" style="background-color:#F9F9F9;" v-b-tooltip.hover title="Lihat detail penilaian"><i class="flaticon-eye"></i></b-button></div></td>
                                          <td><div style="width:80px">{{format_desimal(row.total_ptsp)}}</div></td>
                                          <td><div style="width:120px">{{format_desimal(row.total_ppb)}}&nbsp; &nbsp;<b-button @click="urlppb(row.idptsp)" size="sm" style="background-color:#F9F9F9;" v-b-tooltip.hover title="Lihat detail penilaian"><i class="flaticon-eye"></i></b-button></div></td>
                                          <td><div style="width:80px">{{format_desimal(row.total_akhir)}}</div></td>
                                          <td><div style="text-align:center;">{{row.peringkat_awal}}</div></td>
                                          <td>
                                              <div style="text-align:center;" v-if="row.peringkat_final == 9999">
                                                  Keluar
                                              </div>
                                              <div style="text-align:center;" v-else>{{row.peringkat_final}}</div>
                                          </td>
                                          <td>
                                              <button data-toggle="modal" data-target=".bd-example-modal-lg" @click="getcatatan(row.idptsp)" style="margin:5px" class="btn btn-primary">Lihat <span v-if="row.punya_catatan" class="badge badge-danger">!</span></button>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                            </div>
                        </div>
                        <!--end::Content-->
                    </div>
                    <!--end::Item-->
                </div>
                <!--end: Card Body-->
            </div>

            <!-- lihat semua catatan -->
            <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">Catatan</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                           <div>
                                <div style="overflow-x:auto;">
                                    <table id="example2" class="table table-striped table-bordered" style="width:100%">
                                        <thead>
                                            <tr>
                                                <th>No</th>
                                                <th>Judul Kajian/Berita</th>
                                                <th>Tipe Kajian/Berita</th>
                                                <th>File</th>
                                                <th>Aksi</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="row in catatann" :key="row.id">
                                                <td></td>
                                                <td>{{row.judul}}</td>
                                                <td>
                                                    <div v-if="row.tipe === 'Negatif'" style="color:red">{{row.tipe}}</div>
                                                    <div v-else style="color:blue">{{row.tipe}}</div>
                                                </td>
                                                <td>
                                                    <div v-if="row.file != null">
                                                        <a :href="row.file" target="_blank" class="btn btn-text-dark-50 btn-icon-success font-weight-bold btn-hover-bg-light mr-3 btn-lg"><i class="fa fa-download" aria-hidden="true"/></a>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <button data-toggle="modal" data-target="#modal-edit" @click="getPerCatatan(row.id)" style="margin:5px" class="btn btn-secondary" v-b-tooltip.hover title="Lihat"><i class="fa fa-eye"></i></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
                        </div>
                        </div>
                </div>
            </div>

            <!-- lihat catatan per id -->
            <div class="modal fade" id="modal-edit" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
              <div class="modal-dialog modal-xl" role="document">
                  <div class="modal-content">
                  <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLongTitle">Lihat Catatan</h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                      </button>
                  </div>
                  <div class="modal-body">
                    <form ref="form" @submit.stop.prevent="tambahcatatan">
                      <div class="form-group row">
                          <label class="col-4">Tipe</label>
                          <div class="col-8">
                              <b-form-select disabled v-model="getCatatan.tipe" :options="tipe"></b-form-select>
                          </div>
                      </div>
                      <div class="form-group row">
                            <label class="col-lg-4 col-form-label">Penghimpun Kajian/Berita</label>
                            <div class="col-lg-8">
                                <input disabled type="text" v-model="getCatatan.penghimpun" class="form-control"/>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-lg-4 col-form-label">Judul Kajian/Berita</label>
                            <div class="col-lg-8">
                                <input disabled type="text" v-model="getCatatan.judul" class="form-control"/>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-lg-4 col-form-label">Lembaga</label>
                            <div class="col-lg-8">
                                <input disabled type="text" v-model="getCatatan.lembaga" class="form-control"/>
                            </div>
                        </div>
                        <div class="form-group row">
                        <label class="col-lg-4 col-form-label">Rentang Waktu</label>
                            <div class="col-lg-8">
                                <div class="row">
                                    <div class="col-xl-6">
                                        <!--begin::Input-->
                                        <div class="form-group">
                                            <b-form-datepicker disabled  v-model="getCatatan.tanggal_awal" locale="in" class="mb-2"></b-form-datepicker>
                                        </div>
                                        <!--end::Input-->
                                    </div>
                                    <div class="col-xl-6">
                                        <!--begin::Input-->
                                        <div class="form-group">
                                            <b-form-datepicker disabled  v-model="getCatatan.tanggal_akhir" locale="in" class="mb-2"></b-form-datepicker>
                                        </div>
                                        <!--end::Input-->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-lg-4 col-form-label">Ringkasan Isi Kajian/Berita</label>
                            <div class="col-lg-8">
                                <!-- <vue-editor disabled v-model="getCatatan.deskripsi" /> -->
                                <div v-html="getCatatan.deskripsi"></div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-lg-4 col-form-label">Tautan Kajian/Berita</label>
                            <div class="col-lg-8">
                                <input  disabled type="text" v-model="getCatatan.sumber" class="form-control"/>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-lg-4 col-form-label">Unggah Kajian/Berita</label>
                            <div class="col-lg-8">
                                <b-form-file v-model="getCatatan.file" type="file" ref="file"></b-form-file>
                                <div v-if="getCatatan.file !== null">
                                    <a :href="getCatatan.file" target="_blank" class="btn btn-text-dark-50 btn-icon-success font-weight-bold btn-hover-bg-light mr-3 btn-lg"><i class="fa fa-download" aria-hidden="true"/></a>
                                </div>
                            </div>
                        </div>
                    </form>
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
                      
                  </div>
                  </div>
              </div>
            </div>

            <!-- modal persetujuan selesai input -->
            <div class="modal fade" id="persetujuan" tabindex="-1" role="dialog" aria-labelledby="persetujuan" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="persetujuan">Menyetujui Penetapan Nomine</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <b>Penetapan Nomine Pemda Kabupaten</b><br>
                    <div class="form-group row">
                        <!-- <label class="col-lg-4 col-form-label"></label> -->
                            <div class="col-lg-12">
                                    <b-form-radio  id="setuju" v-model="persetujuan" name="some-radios" value="Setuju">Setuju</b-form-radio>
                                    <b-form-radio id="tidaksetuju" v-model="persetujuan" name="some-radios" value="Tidak">Tidak Setuju</b-form-radio>
                            </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal">Batal</button>
                    <button type="button" class="btn btn-primary" @click="persetujuankab" style="background-color: #030A8C; border-color: #030A8C" data-dismiss="modal">Simpan</button>
                </div>
                </div>
            </div>
            </div>

        </div>
    <!-- </div>
  </div> -->
</template>
<style>
  div.messages {
  /* background-color: lightblue; */
  /* width: 110px; */
  height: 500px;
  overflow: auto;
}
</style>
<style lang="">
  
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from 'axios';
import { VueEditor } from "vue2-editor";
export default {
  components:{VueEditor},
  data() {
      return {
        test:'hai',
        url: localStorage.getItem('baseapi'),
        token: localStorage.getItem('id_token'),
        user: JSON.parse(localStorage.getItem('user')),
        tahun: new Date().getFullYear(),
        totskor1:[],
        totskor2:[],
        search: '',
        selectedprovinsi:'',
        provinsi:'',
        dataprovinsi1:[],
        dataprovinsi2:[],
        persetujuan:'',
        selectedperingkatbaru:'-',
        peringkatbaru: [
         { value: '-', text: '-' },
         { value: 1, text: 1 },
         { value: 2, text: 2 },
         { value: 3, text: 3 },
         { value: 4, text: 4 },
         { value: 5, text: 5 },
         { value: 1000, text: 'Keluar' },
       ],
        searchcatatan:'',
        catatann:[],
        getCatatan:{
            id:'',
            tipe:'',
            penghimpun:'',
            judul:'',
            deskripsi:'',
            sumber:''
        },
        tipe: [
         { value: 'Negatif', text: 'Negatif' },
         { value: 'Positif', text: 'Positif' },
        ],
        noCatatan:'',
        setuju:[],
      }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Rekapitulasi" }]);
    this.loaddata()
  },
  methods: {
    format_desimal(value){
         if (value) {
           return value.replace(".",",");
          }
    },
    loaddata(){
    //  lihat data apakah user sudah setuju
      axios.get(this.url+"/list_setuju_kabupaten?filter=nama,=,"+this.user.name+";tahun,=,"+this.tahun, {
            headers: {
                'xth': this.token
            }
        })
        .then(response => {
            this.setuju = response.data.data;
        }).catch(error=>{
            return error
      });
      
    //   load data nomine
      axios.get(this.url+`/total_nilai_pemda_rank/list?daerah=kabupaten&sort=peringkat_final asc`, {
            headers: {
                'xth': this.token
            }
        })
        .then(response => {
            this.totskor1 = response.data.data;
            this.dataprovinsi1 = this.totskor1;
            this.initDatatable1();
        }).catch(error=>{
          return error
      });
    
    },

    urlppkprovinsi(kode_wilayah){
      let routeData = this.$router.resolve(
        {
          path: `/rekapitulasi/detailppkprovinsi/${kode_wilayah}`, 
        });
        window.open(routeData.href, '_blank');
    },

    // melakukan perstujuan
    persetujuankab(){
      let data = this.user.alamat
      let temp = data.split("-");
      console.log(temp)
      const persetujuan = this.persetujuan
      const nama = this.user.name
      const instansi = temp[0]
      const posisi = temp[1]
      const jabatan = temp[2]
      const tahun = this.tahun
      axios
        .post(
        this.url+"/list_setuju_kabupaten",
        {
            persetujuan,
            nama,
            instansi,
            posisi,
            jabatan,
            tahun,
        }, {
            headers: {
                'xth': this.token
            }
        })
        .then((res) => {
            this.$bvToast.toast('Berhasil Disimpan', {
                title: `Success `,
                variant: `success`,
                solid: true
            })
            this.loaddata()
        return res;
        })
        .catch((err) => {
          this.$bvToast.toast('Gagal Disimpan', {
                title: `Failed `,
                variant: `danger`,
                solid: true
            })
          return err;
        });
    },
    initDatatable1() {
        setTimeout(() => {
        var t = $('#example').DataTable({
            "pagingType": "full_numbers",
            "language": {
                "info": "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
                "paginate": {
                    "first":      "Awal",
                    "last":       "Akhir",
                    "next":       "Selanjutnya",
                    "previous":   "Sebelumnya"
                },
            },
            dom: 'Bfrtip',
            "fnRowCallback": function(nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                //debugger;
                var index = iDisplayIndexFull + 1;
                $("td:first", nRow).html(index);
                return nRow;
            },
            columnDefs: [{
                targets: 0,
                render: function(data, type, row, meta) {
                console.log(meta.row);
                console.log(type == 'export'? meta.row : data);
                  return type == 'export'? meta.row + 1 : data;
                }
            }],
            buttons: [
                {
                  extend: 'excel',
                    text : 'Excel',
                    title: 'Rekapitulasi Nomine Pemda Kabupaten',
                    exportOptions: {
                        columns: [ 0, 1, 2, 3, 4, 5, 6, 7, 8],
                        orthogonal: 'export',
                    },
                    filename: function(){
                        var today = new Date();
                        // var n = d.getTime();
                        var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                        
                        return 'Rekapitulasi Nomine Pemda Kabupaten' + date;
                    },
                },
                {
                  extend: 'pdf',
                    text : 'PDF',
                    title: 'Rekapitulasi Nomine Pemda Kabupaten',
                    exportOptions: {
                        columns: [ 0, 1, 2, 3, 4, 5, 6, 7, 8],
                        orthogonal: 'export',
                    },
                    filename: function(){ 
                        var today = new Date();
                        // var n = d.getTime();
                        var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                        
                        return 'Rekapitulasi Nomine Pemda Kabupaten' + date;
                    },
                }
            ],
            "lengthMenu": [
                [10, 25, 50, -1],
                [10, 25, 50, "All"]
            ],
            // order: [[7, 'desc'], [3, 'desc']],
            responsive: true,
            destroy: true,
            retrieve: true,
            autoFill: true,
            // colReorder: true,

        });
        t.on( 'order.dt search.dt', function () {
        t.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
            cell.innerHTML = i+1;
        } );
        } ).draw();
        }, 300);
    },
    initDatatable2() {
        setTimeout(() => {
        var t = $('#example2').DataTable({
            "pagingType": "full_numbers",
            "language": {
                "info": "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
                "paginate": {
                    "first":      "Awal",
                    "last":       "Akhir",
                    "next":       "Selanjutnya",
                    "previous":   "Sebelumnya"
                },
            },
            "lengthMenu": [
                [10, 25, 50, -1],
                [10, 25, 50, "All"]
            ],
            // order: [[8, 'asc']],
            responsive: true,
            destroy: true,
            retrieve: true,
            autoFill: true,
            // colReorder: true,

        });
        t.on( 'order.dt search.dt', function () {
        t.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
            cell.innerHTML = i+1;
        } );
        } ).draw();
        }, 300);
    },

    // lihat semua data catatan
    getcatatan(idptsp){
        $('#example2').DataTable().destroy();
        this.catatann = null,
        axios.get(this.url+"/catatan_ta_kabupaten?filter=idptsp,=,"+idptsp+";tahun,=,"+this.tahun, {
                headers: {
                    'xth': this.token
                }
            })
            .then(response => {
                var catatannomine = response.data.data
                this.catatann = catatannomine;
                this.initDatatable2()
            }).catch(error=>{
                return error
                });
    },

    // catatan per id
    getPerCatatan(id){
        axios.get(this.url+"/catatan_ta_kabupaten?filter=id,=,"+id, {
                headers: {
                    'xth': this.token
                }
            }).then(response => {
            this.getCatatan.id = id;
                this.getCatatan.tipe = response.data.data[0].tipe;
                this.getCatatan.penghimpun = response.data.data[0].penghimpun;
                this.getCatatan.judul = response.data.data[0].judul;
                this.getCatatan.deskripsi = response.data.data[0].deskripsi;
                this.getCatatan.sumber = response.data.data[0].sumber;
                this.getCatatan.tanggal_awal = response.data.data[0].tanggal_awal;
                this.getCatatan.tanggal_akhir = response.data.data[0].tanggal_akhir;
                this.getCatatan.lembaga = response.data.data[0].lembaga;
                this.getCatatan.idptsp = response.data.data[0].idptsp;
                this.getCatatan.file = response.data.data[0].file;
        });
    },
    urlptsp(kode_wilayah){
      let routeData = this.$router.resolve(
        {
          path: `/rekapitulasi/detailpm/${kode_wilayah}`, 
        });
        window.open(routeData.href, '_blank');
    },
    urlppb(kode_wilayah){
      let routeData = this.$router.resolve(
        {
          path: `/rekapitulasi/detailppb/${kode_wilayah}`, 
        });
        window.open(routeData.href, '_blank');
    },
    urlhipmi(kode_wilayah){
      let routeData = this.$router.resolve(
        {
          path: `/rekapitulasi/detailhipmi/${kode_wilayah}`, 
        });
        window.open(routeData.href, '_blank');
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  },
  created(){
    
  }
};
</script>
